const order={
    addToCartFromPosition(event, articleNumber, possnr){
        let qty=document.getElementById("qty_"+possnr).value;
        let button=document.getElementById("addToCard_"+possnr)

        if(qty>0){
            shopApi.addToCart(articleNumber, qty).then(data=>{
                button.classList.toggle('added');
            }, error=>{
                window.alert(error.message);
            })
        }
        
    },

    qtyPlus(possnr){
        var inpt = $(document.getElementById("qty_"+possnr));
        var val = parseInt(inpt.val());
        if (val < 0) inpt.val((val = 0));
        inpt.val(val + 1);
    },

    qtyMinus(possnr){
        var inpt = $(document.getElementById("qty_"+possnr));
        var val = parseInt(inpt.val())-1;
        if (val < 0) inpt.val((val = 1));
        if (val == 0) return;
        inpt.val(val);
    }
}

export default order;
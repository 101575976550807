const profile={
    saveProfile(){
        //profile object gets defined in "profil-daten-aender.hmtl" template
        utils.inputToObject(userprofile,'#profiledata','input[id^=ANP]')
        utils.inputToObject(userprofile,'#profiledata','select[id^=ANP]')
        showWait();
        shopApi.updateProfile(userprofile).then((data)=>{
            hideWait();
            window.location="/de/profil/main";
        }, (error)=>{
            window.location="/de/profil/main";
            hideWait();
        })
    },

    saveNewPassword(){
        //validate
        let pwd1=document.getElementById("NEW1_ANP_4983_16").value;
        let pwd2=document.getElementById("NEW2_ANP_4983_16").value;
        let errorDiv=document.getElementById("error_password_1");
        if(pwd1==pwd2){
            errorDiv.style.display="none";
            userprofile.ANP_4983_16=pwd1;
            shopApi.updateProfile(userprofile).then((data)=>{
                hideWait();
                window.location="/de/profil/main";
            }, (error)=>{
                window.location="/de/profil/main";
                hideWait();
            })
        } else {
            errorDiv.style.display="block";
        }
    }
}
export default profile;